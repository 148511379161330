<template>
    <div>
        <Toast />
        <div class="card">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>Search</h5>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">Category Name</label>
                            <InputText id="googlurl" type="text" placeholder="Search by category name" v-model="title" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">Category Type</label>
                            <Dropdown v-model="category_id" :options="dropdownBoardCategoryValues" optionValue="id" optionLabel="title" placeholder="Select " />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">Country</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id" optionLabel="country_name" placeholder="Select " />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">Creation Date</label>
                            <Calendar inputId="range" v-model="post_date" selectionMode="range" :manualInput="false" placeholder="YYYY/MM/DD - YYYY/MM/DD"></Calendar>
                            
                        </div>
                       
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                <div class="p-mb-4 p-mb-lg-0"></div>
                <div>
                    <Button label="Search" icon="pi pi-search" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="searchBulletinCategoryManagement()"></Button>
                    <Button label="Reset" icon="pi pi-replay" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="resetBulletinCategoryManagement()"></Button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-d-flex p-jc-between p-mb-2">
                        <h4>Bulletin Category List</h4>
                        
                    </div>
                    <DataTable :value="products" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" :loading="loading1" :filters="filters1" responsiveLayout="scroll">
                        <ConfirmDialog group="dialog" />

                        <template #empty>{{$t('Nodata')}}</template>
                        <template #loading>Loading data. wait.</template>

                        <Column field="#" header="#" style="min-width: 5rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Sl. No.</span>
                                {{ data.sl_no }}
                            </template>
                        </Column>
                        <Column field="companyname" header="Category" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Category</span>
                                {{ data.title }}
                            </template>
                        </Column>
                        <Column field="product" header="Category type" style="min-width: 13rem">
                            <template #body="{  }">
                                <span class="p-column-title">Category type</span>
                                <!-- {{ data.country_name }} -->
                            </template>
                        </Column>
                        <Column field="service" header="Country" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Country</span>
                                {{ data.country_name  }}
                            </template>
                        </Column>
                        <Column field="Status" header="Creation Date" style="min-width: 12rem">
                            <template #body="{  }">
                                <span class="p-column-title">Creation Date</span>

                                <!-- {{ data.country_name }} -->
                            </template>
                        </Column>

                        <Column field="Creation-Date" header="Last edited Date" style="min-width: 12rem">
                            <template #body="{}">
                                <span class="p-column-title">Last edited Date</span>
                                -
                                <!-- {{ dateformat(data.createdDate) }} -->
                            </template>
                        </Column>

                        <Column field="Creation-Date" header="Exposure" style="min-width: 12rem">
                            <template #body="{  }">
                                <span class="p-column-title">Exposure</span>
                                <InputSwitch  />        
                            </template>
                        </Column>

                        

                        <Column field="Actions" header="Action">
                            <template #body="{ data }">
                                <span class="p-column-title">Actions</span>
                                <p style="display: none">{{ data.status }}</p>
                                <div style="display: flex">
                                    <router-link :to="'/BulletinCategoryManagementview/' + data.id">
                                        <Button label="info" class="n-wrap p-button-outlined p-button-info p-mr-2 p-mb-2">
                                            <i class="pi pi-eye p-mr-2"></i>
                                        </Button>
                                    </router-link>
                                    <router-link :to="'/ManagerManagementEdit/' + data.id">
                                        <Button label="help" class="n-wrap p-button-outlined p-button-help p-mr-2 p-mb-2" >
                                            <i class="pi pi-pencil p-mr-2"></i>
                                        </Button>
                                    </router-link>
                                    
                                        <Button icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2"  @click="deleteNote(data.id)"/>
                                    
                                    <!-- <Button :label="$t('button.delete')" icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2" @click="confirm(data.id)" /> -->
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import BulletinCategoryManagementService from '../../../service/API/SuperAdmin/BulletinCategoryManagementService.js';
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            dropdownstatusValues: [
                { name: 'Active', code: 'active' },
                { name: 'Inactive', code: 'deleted' },
            ],
            serial: 0,
            dropdownstatusValue: null,

            isModalVisible: false,
            dropdownCountryValues:'',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues:'',
            dropdownBoardCategoryValue:null,
            products: null,
            loading1: true,
            deletedID: null,
            sl_no: '',
            title: '',
            country_id: '',
            category_id: '',
            createdDate: '',
            lastDate:'',
            status: '',
            total: '',
            id:'',
        };
    },
    created() {
        this.bulletinCategoryManagementService = new BulletinCategoryManagementService();
    },
    mounted() {
        const route = useRoute();
        console.log(route.params);
        
        this.bulletinCategoryManagementService
            .getBulletinCategoryManagementList(this.title, this.country_id, this.is_free)
            .then((res) => {
                this.products = res.data.data.board_category;
                this.total = res.data.data.length;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

                console.log(res.data.data.board_category);
            })

            .catch((err) => console.log(err));

            this.bulletinCategoryManagementService
            .getCountrydropdown()
            .then((data) => {
                this.dropdownCountryValues = data.data.data.country;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownCountryValues);
            })
            .catch((err) => console.log(err));


            // this.bulletinCategoryManagementService
            // .getBoardCategorydropdown()
            // .then((data) => {
            //     this.dropdownBoardCategoryValues = data.data.data.board_category;
            //     // this.products = data;
            //     this.loading1 = false;
            //     // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
            //     console.log(this.dropdownBoardCategoryValues);
            // })
            // .catch((err) => console.log(err));
    },
    methods: {
        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },

        resetBulletinCategoryManagement() {
            this.title = '';
            this.country_id = '';
            this.is_free = '';
            // this.calendarValue1 = '';
            this.loading1 = true;

            this.bulletinCategoryManagementService
                .getBulletinCategoryManagementList(this.title, this.country_id, this.is_free)
                .then((res) => {
                    this.products = res.data.data.board_category;
                    this.total = res.data.data.total;
                    this.loading1 = false;
                    //console.log(data);
                })
                .catch(() => {
                    this.products = [];
                    this.loading1 = false;
                });
        },

        
        searchBulletinCategoryManagement() {
            if (this.title === '' && this.country_id === '' && this.is_free === '' ) {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            } else {
                // if(this.startDate!=''){
                //     this.searchdate = this.addDay(this.startDate)
                // } else {
                //     this.searchdate = ""
                // }
                this.bulletinCategoryManagementService
                    .getBulletinCategoryManagementList(this.title, this.country_id, this.is_free)
                    .then((res) => {
                        this.products = res.data.data.board_category;
                        // this.total = res.data.data.total;
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch(() => {
                        this.products = [];
                        this.loading1 = false;
                    });
            }
        },
        
        dateformat(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },

        startdateformat(value) {
            if (value) {
                return moment(String(value)).format('YYYY/MM/DD ');
            }
        },

        deleteNote: function (id) {
            console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirm',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-trash',
                acceptLabel: "Confirm",
                rejectLabel: "cancellation",
                accept: () => {
                    axios({ method: 'delete', url: '/asnhist-dev/api/v1/admin/manager/delete', data: { deleteIdArray: id } }).then(function (response) {
                        console.log(response);
                        self.managerService
                            .getManagerList(self.name, self.email, self.mobile, self.country_id, self.status)
                            .then((res) => {
                                self.products = res.data.data.manager;
                                //self.total = res.data.data.total;
                                self.loading1 = false;
                                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

                                console.log(res);
                            })
                            .catch((err) => console.log(err));

                        // let i = this.products.map(data => data.id).indexOf(id);

                        // this.products.splice(i, 1)
                    });
                    this.$toast.add({ severity: 'info', summary: '삭제되었습니다.', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: 'An error occurred', detail: 'You have rejected', life: 3000 });
                },

            });

        },
        
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}
::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}
::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;
    .p-progressbar-value {
        background-color: #607d8b;
    }
}
::v-deep(.p-datatable .p-sortable-column .p-column-title) {
    display: block;
}
.p-datatable .p-column-filter {
    display: none;
}
.table-header {
    display: flex;
    justify-content: space-between;
}
.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
    &.status-qualified {
        background: #c8e6c9;
        color: #256029;
    }
    &.status-unqualified {
        background: #ffcdd2;
        color: #c63737;
    }
    &.status-negotiation {
        background: #feedaf;
        color: #8a5340;
    }
    &.status-new {
        background: #b3e5fc;
        color: #23547b;
    }
    &.status-renewal {
        background: #eccfff;
        color: #694382;
    }
    &.status-proposal {
        background: #ffd8b2;
        color: #805b36;
    }
}
.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}
@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}
.product-image {
    width: 100px;
    height: 50px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.orders-subtable {
    padding: 1rem;
}
.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }
    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }
    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}
.order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
    &.order-delivered {
        background: #c8e6c9;
        color: #256029;
    }
    &.order-cancelled {
        background: #ffcdd2;
        color: #c63737;
    }
    &.order-pending {
        background: #feedaf;
        color: #8a5340;
    }
    &.order-returned {
        background: #eccfff;
        color: #694382;
    }
}
.p-datatable {
    .p-sortable-column {
        &.p-highlight {
            &:hover {
                background: rgb(216 217 243);
                color: #464df2;
            }
        }
    }
}
.true-icon {
    color: #256029;
}
.false-icon {
    color: #c63737;
}
.w-100 {
    width: 100%;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}
</style>